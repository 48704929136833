require('./reqon');
$( document ).ready(function() {

    var config = {
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        processing: function ($element, status) {
            $element.find('[type="submit"]').prop('disabled', status);
            if(status){
                $element.find('[type="submit"]').val($element.find('[type="submit"]').attr('data-wait'));
            }else{
                $element.find('[type="submit"]').val($element.find('[type="submit"]').attr('data-value'));
            }
        },
        respond: function ($element, json) {
            if (json.errors) {
                $('.is-invalid').removeClass('is-invalid');
                $('.invalid-feedback').remove();
    
                $.each(json.errors, (name, errors) => {
                    const field = name.includes('.') ? name.split('.').join('][') : name;
                    const $errorElement = $(`[name='${field}']`);
                    if ($errorElement.length) {
                        const feedback = $('<div>').addClass('invalid-feedback').html(errors[0]);
                        $errorElement.addClass('is-invalid').closest('div').append(feedback);
                        $errorElement.one('change', () => {
                            $errorElement.removeClass('is-invalid').closest('div').find('.invalid-feedback').remove();
                        });
                    }
                });
    
                const firstError = $('.invalid-feedback').first();
                if (firstError.length) {
                    $('html, body').animate({ scrollTop: firstError.offset().top - 80 }, 'smooth');
                }
            }
    
            if (json.reset) {
              const $form = $element.closest('form');
              if ($form.length > 0) {
                  $form[0].reset();
              }
            }
    
        }
    }

    $('html').reqon(config);

    if($('.mobile-toggle').length > 0) {
        $('.mobile-toggle').on('click', function () {
            $('body').addClass('openu-menu');
        });
    }

    if($('.close-toggle').length > 0) {
        $('.close-toggle').on('click', function () {
            $('body').removeClass('openu-menu');
        });
    }

    if($('.testi-slider').length > 0) {
        $('.testi-slider').slick({
            dots: true,
            infinite: true,
            speed: 300,
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 2
                    }
                }
            ]
        });
    }

    if($('.about-slider').length > 0) {
        $('.about-slider').slick({
            dots: true,
            infinite: true,
            speed: 300,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            pauseOnHover: false,
        });
    }

    if($('.slider').length > 0) {
        $('.slider').slick({
            dots: false,
            infinite: true,
            speed: 1000,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: false,
            autoplay: true
        });
    }
    
    if($('.geeks').length > 0) {
        var typed = new Typed(".geeks", {
            strings: ["CO-mapped Question Papers for any exam", "Course Outcomes for any course or topic", "Innovative Assessments like assignment topics/seminars"],
            loop: true,
            startDelay: 100,
            typeSpeed: 80,
            backDelay: 1000,
            backSpeed: 20
        });
    }
});
 

